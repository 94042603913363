import { product_selected } from "../../External/product_selected";
import { mapping_cache_server } from "./mapping_cache_server";

export class mapping {
    public id: string;
    public mapping_code: string;
    public ops_mapping_code: string;
    public product_id: string;
    public product: product_selected;
    public mapping_cache_servers: mapping_cache_server[] = [];
}
